<template>

  <div>



    <div class="divSearch">

      <div class="dCount">
        <div>检索结果:</div>
        <div>{{ dataValue.allModels.output.allCount}}条</div>
      </div>
      <div class="dCount">
        <div>数据总量:</div>
        <div>{{ dataValue.allModels.output.allPriceCount}}条</div>
      </div>

      <a-input class="model" placeholder="型号名称" @pressEnter="allEvent.allModels.toPage(1)" v-model:value="dataValue.allModels.input.modelKey"/>

      <a-select class="company" v-model:value="dataValue.allModels.input.brandName"
                @change="allEvent.allModels.toPage(1)">
        <a-select-option value="">全部品牌</a-select-option>
        <template v-for="brand in dataValue.allBrand.output.list">
          <a-select-option :value="brand['brand']">{{ brand['brand'] }}</a-select-option>
        </template>
      </a-select>

      <a-select class="moneyType"  v-model:value="dataValue.allModels.input.moneyTypeValue"  @change="allEvent.allModels.http">
        <a-select-option value="5">转换为人民币</a-select-option>
        <a-select-option value="3">转换为美元</a-select-option>
      </a-select>
      <a-button class="buttSearch" @click="allEvent.allModels.toPage(1)"><search-outlined /></a-button>





    </div>


    <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
    <div v-if="dataValue.loading === false" class="divTab">
      <table class="ahuiTable">
        <tr>
          <th rowspan="2">编号</th>
          <th rowspan="2" class="model2">型号</th>
          <th rowspan="2">品牌</th>
          <th rowspan="2">价格总数</th>
          <th colspan="3">最新价格1 ({{dataValue.allModels.output['moneyTypeName']}})</th>
          <th colspan="3">最新价格2 ({{dataValue.allModels.output['moneyTypeName']}})</th>
          <th colspan="3">最新价格3 ({{dataValue.allModels.output['moneyTypeName']}})</th>
          <th rowspan="2">&nbsp;</th>
          <th rowspan="2">分析</th>
        </tr>
        <tr>
          <th>价格</th>
          <th>类型</th>
          <th>日期</th>
          <th>价格</th>
          <th>类型</th>
          <th>日期</th>
          <th>价格</th>
          <th>类型</th>
          <th>日期</th>
        </tr>
        <tr v-for="aRow in dataValue.allModels.output.list">
          <td class="min">{{ aRow['indexId'] }}</td>
          <td>{{ aRow['modelName'] }}</td>
          <td style="text-align: center;">{{ aRow['brand'] }}</td>
          <td class="min">{{ aRow['priceList'].length }}</td>
          <td class="min price" v-html="allEvent.allModels.showPriceList(aRow,'price',0)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTypeName',0)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTime',0)"></td>


          <td class="min price" v-html="allEvent.allModels.showPriceList(aRow,'price',1)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTypeName',1)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTime',1)"></td>


          <td class="min price" v-html="allEvent.allModels.showPriceList(aRow,'price',2)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTypeName',2)"></td>
          <td class="min" v-html="allEvent.allModels.showPriceList(aRow,'priceTime',2)"></td>


          <td class="min"><a class="aBQSet" @click="allEvent.showEvent.open(aRow)">明细</a></td>
          <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
        </tr>
        <tr>
          <td colspan="100" class="pages">
            <APages :number_of_page="dataValue.allModels.output.numberOfPage"
                    :this_page="dataValue.allModels.output.page" :all_page="dataValue.allModels.output.allPage"
                    @toPage="allEvent.allModels.toPage" :all_count="dataValue.allModels.output.allCount"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>

  <a-drawer
      title="查看价格明细"
      width="830"
      :visible="dataValue.showEvent.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="left"
      @close="allEvent.showEvent.close"
  >

    <showPage @goClose="allEvent.showEvent.close" :model_row="dataValue.showEvent.priceRow" :money_type_name="dataValue.allModels.output['moneyTypeName']"  />
  </a-drawer>

  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>

</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import showPage from "./price_show"
import biModel from "@/view/bi/bi_model"


export default {
  name : "price_list",
  emits : [ 'setMenuKey' ],
  components : { APages, showPage, biModel },
  setup(props, { emit }) {
    emit('setMenuKey', 'datacenter.xhjgsj');


    let dataValue = reactive({
      bi:{
        show: false,
        modelName : ""
      },
      loading : false,
      showEvent : {
        show : false,
        priceRow : null
      },

      attributeSet : {
        show : false,
        modelRow : null
      },


      allBrand : {
        input : {
          "brandKey" : ""
        },
        output : {
          "allCount" : 0,
          "list" : []
        }
      },

      allModels : {
        showPriceCount : 3, // 显示价格列表显示几行
        input : {
          "brandName" : "",
          "modelKey" : "",
          "page" : 1,
          "moneyTypeId" : 5,
          "moneyTypeValue" : "5",
          "numberOfPage" : 20,
          "attributeKeys" : []
        },
        output : {
          "allCount" : 0,
          "allPage" : 0,
          "page" : 0,
          "allPriceCount": 0,
          "numberOfPage" : 0,
          "moneyTypeName": "",
          "attributeNames" : [],
          "attributeXXNames" : [],
          "list" : []
        }
      }
    })

    let allEvent = {
      bi : {
        open : (row)=>{
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },
        close: ()=>{
          dataValue.bi.show = false;
        }
      },
      showEvent : {

        open :(row)=>{
          dataValue.showEvent.show = true;
          dataValue.showEvent.priceRow = row;
        },

        close :()=>{
          dataValue.showEvent.show = false;
        }
      },

      allBrand : {

        http : () => {

          proxy.post('/admin/data.center/all.brand', dataValue.allBrand.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.allBrand.output.allCount = res.data["allCount"];
              dataValue.allBrand.output.list = res.data["list"];
            }
          })

        }

      },

      allModels : {

        showPriceList :(row, nodeName, index)=>{

          if( row['priceList'].length > index ){
            return row['priceList'][index][nodeName]
          }else{
            return "&nbsp;"
          }

        },

        attrGet : (row, noteName) => {

          let returnKey = "";
          row['attributes'].forEach((v) => {
            if (v['key'] === noteName) returnKey += v['value'] + ", "
          })
          return returnKey.substring(0, returnKey.length - 2);
        },

        toPage : (pageId) => {
          dataValue.allModels.input.page = pageId;
          allEvent.allModels.http();
        },


        http : () => {

          dataValue.loading = true;

          dataValue.allModels.input.moneyTypeId = parseInt(dataValue.allModels.input.moneyTypeValue);

          proxy.post('/admin/data.center/all.models', dataValue.allModels.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {

              dataValue.allModels.output.allCount = res.data["allCount"];
              dataValue.allModels.output.allPriceCount = res.data["allPriceCount"];
              dataValue.allModels.output.allPage = res.data["allPage"];
              dataValue.allModels.output.page = res.data["page"];
              dataValue.allModels.output.numberOfPage = res.data["numberOfPage"];
              dataValue.allModels.output.attributeNames = res.data["attributeNames"];
              dataValue.allModels.output.attributeXXNames = res.data["attributeXXNames"];
              dataValue.allModels.output.moneyTypeName = res.data["moneyTypeName"];
              dataValue.allModels.output.list = res.data["list"];

              dataValue.loading = false;

            }
          })
        }

      },

      init : () => {
        allEvent.allModels.http();
        allEvent.allBrand.http();
      }
    }

    allEvent.init();


    return {
      dataValue,
      allEvent
    }

  }
}
</script>

<style scoped>
.dCount{ margin-top: 10px; margin-bottom: 15px; float: left; width: 100%; border-bottom: 1px solid #dddddd; padding-bottom: 5px; }
.dCount div:nth-child(1){ float: left; margin-left: 5px; }
.dCount div:nth-child(2){ float: right; color: saddlebrown; }
.dTitle{ float: left; width: 100%; clear: both; border-bottom: 1px solid #eeeeee; margin-bottom: 8px; margin-left: 5px;
  margin-top: 10px; padding-bottom: 3px; }
.dTitle .l{ float: left; font-weight:bold;}
.dTitle .r{ float: right;margin: 0 10px 0 0; position: relative; top:2px; cursor: pointer; transition: all 1s; }
.dTitle .r:hover{ color: #225DDC; transform: rotateZ(360deg); transition: all 0.4s; }
.divSearch { position: fixed; top: 60px; left: 0; width: 200px; padding: 10px ; }
.divSearch .company { clear: both; width: 100%; margin-bottom: 15px; }
.divSearch .moneyType { clear: both; width: 145px; margin-bottom: 15px; }
.divSearch .model { clear: both; width: 100%; margin-bottom: 15px; text-transform:uppercase; }
.divSearch .buttSearch{ float: right; width: 30px; padding: 0 0 0 2px; }
.divTab{ position: absolute; top: 0; left: 200px; width: calc( 100% - 200px ); padding: 10px 10px 10px 0; }

.loadingClass { width: 100%; text-align: center; padding-top: 100px; }

.model2 { white-space: nowrap; }
.center { text-align: center; }
.aBQSet{ margin: 0 3px 0 3px; white-space: nowrap; }


</style>