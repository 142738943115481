<template>

  <div>
    <div id="main" style="height: 300px;width:100%;" ></div>

    <table class="ahuiTable">
      <tr>
        <th>&nbsp;</th>
        <th>型号</th>
        <th>品牌</th>
        <th>价格类型</th>
        <th>价格</th>
        <th>货币</th>
        <th>更新日期</th>
        <th>来源</th>
      </tr>
      <tr v-for="(row,i) in dataValue.modelRow['priceList']">
        <td style="text-align:center;">{{ i + 1 }}</td>
        <td>{{ row['modelName'] }}</td>
        <td style="text-align: center;">{{ row['brand'] }}</td>
        <td class="min">{{ row['priceTypeName'] }}</td>
        <td class="price">{{ row['price'] }}</td>
        <td class="min">{{ dataValue.moneyTypeName }}</td>
        <td class="min">{{ row['priceTime'] }}</td>
        <td class="min">{{ row['source'] }}</td>
      </tr>
    </table>

  </div>

</template>

<script>
import {onMounted, reactive} from "vue";
import * as echarts from 'echarts'



export default {
  name : "price_show",
  props : [ "model_row", "money_type_name" ],
  setup(props, { emit }) {

    let dataValue = reactive({
      chartList : [],
      modelRow : props.model_row,
      moneyTypeName : props.money_type_name
    });

    let allEvent = {

      myChart : () => {

        let data1 = [], data2 = [], data3 = [];

        dataValue.chartList.forEach((v)=>{
          data1.push(v['priceTime']);
          data2.push(v['price'])
          data3.push(v['price']*0.9)
        });


        const myChart = echarts.init(document.getElementById("main"));
        myChart.setOption({
          title : {
            text : dataValue.modelRow['modelName'] + ' 的销售价格变化图表',
            subtext : '共有' + dataValue.modelRow['priceList'].length + '条价格数据 ('+ dataValue.moneyTypeName +')',
            left : '50%',
            textAlign : 'center'
          },
          tooltip : {
            axisPointer : {
              type : 'shadow',
            }
          },
          grid : {
            left : '3%',
            right : '4%',
            bottom : '3%',
            containLabel : true
          },
          xAxis : {
            type : 'category',
            data : data1,
            axisTick : {
              alignWithLabel : true
            }
          },
          yAxis : [
            {
              type : 'value'
            }
          ],
          series : [
            {
              type : 'bar',
              data : data2,
              showBackground : true,
              backgroundStyle : {
                color : 'rgba(180, 180, 180, 0.2)'
              }
            },
            {
              data: data3,
              type: 'line',
              smooth: true
            }
          ]
        });
      }
    }
    onMounted((v) => {

      // 只统计销售记录
      for( let i=dataValue.modelRow['priceList'].length-1; i>=0; i-- ){
        let v = dataValue.modelRow['priceList'][i];
        if( v['priceTypeId'] !== 20 ){
          dataValue.chartList.push(v);
        }
      }

      if (dataValue.chartList.length > 1) allEvent.myChart();
      else document.getElementById("main").style.display = "none";
    });


    return {
      dataValue
    }
  }
}

</script>

<style scoped>
* { touch-action: pan-y!important; }

</style>